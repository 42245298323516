import * as React from "react"
import Layout from "../components/ui/Layout"
import Seo from "../components/ui/Seo"
import Root from "../components/pages/success/Root"

const SuccessPage = () => {
  return (
    <Layout>
      <>
        <Seo
          title="Thank you for contacting us | 株式会社FunMeets"
          description=""
        />
        <Root />
      </>
    </Layout>
  )
}

export default SuccessPage
